<template>
  <div class="footer-holder">
    <footer>
      <div>
        <img src="../assets/logotopnetblue.png" />
      </div>
      <div>
        <img src="../assets/sobrehomeanatel.png" />
      </div>
      <vue-cookie-comply
        headerTitle="Aviso Legal"
        headerDescription="Nós utilizamos cookies para otimizar e aprimorar sua navegação do site,
        manter uma melhoria contínua no conteúdo oferecido e aperfeiçoar a
        experiência de nossos usuários. Todos os cookies, exceto os essenciais,
        necessitam de seu consentimento para serem executados."
        @on-accept-all-cookies="onAccept"
        @on-save-cookie-preferences="onSavePreferences"
        acceptAllLabel="Aceitar todos"
      />
    </footer>
    <div class="top-info">
      <p><strong>TOPNET-MS LTDA ME</strong></p>
      <p>cnpj: 24.625.950/0001-70</p>
      <p>email: topnetms@gmail.com</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      preferences: [
        {
          title: 'Performance',
          description: 'serviços que podemos oferecer',
          items: [{ label: 'Active', value: 'performance', isRequired: true }],
        },
      ],
    };
  },
  methods: {
    onAccept() {
      console.log('User has accepted all cookies');
    },
    onSavePreferences(preferences) {
      console.log(preferences); // ['performance', 'ga', 'newRelic']
    },
  },
};
</script>

<style lang="scss">
.cookie-comply {
  position: fixed !important;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: minmax(0, 1fr) 300px !important;
  }

  &__actions {
    grid-auto-flow: dense;
    direction: rtl;
    @media only screen and (min-width: 1024px) {
      grid-template-columns: none !important;
    }
  }

  &__button:first-child {
    max-width: 2px;
    display: none;
  }
}
.footer-holder {
  width: 100%;
}

.top-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1rem solid #134590;
  background-color: #134590;
  padding: 1.5rem 0 0.5rem 0;
  p {
    color: #fff;
    margin: 0;
    font-size: 12px;
  }
}

footer {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;

  div {
    margin-right: 2rem;
  }
  img {
    height: 85px;
  }

  @media only screen and (max-width: 1024px) {
    img {
      height: 75px;
    }
  }
  @media only screen and (max-width: 768px) {
    img {
      height: 55px;
    }
  }
  @media only screen and (max-width: 520px) {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 2rem;
      margin-right: 0;
    }
  }
}
</style>
